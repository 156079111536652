@font-face {
  font-family: "synthese";
  src: url("./fonts/synthese-regular-TRIAL-BF63b781e43e16d.otf") format("otf"),
  url("./fonts/syntheseweb-regular-TRIAL-BF63b781f35bc84.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "synthese";
  src: url("./fonts/synthese-light-TRIAL-BF63b781e46ea97.otf") format("otf"),
  url("./fonts/syntheseweb-light-TRIAL-BF63b781f2cbce4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "synthese";
  src: url("./fonts/synthese-light-TRIAL-BF63b781e46ea97.otf") format("otf"),
  url("./fonts/syntheseweb-light-TRIAL-BF63b781f2cbce4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

html {
  background: #EEE9E4;
}


.mobile_gallery {
  display: none;
  background: #E3DBD4;
  margin: 30px -20px;
  @media screen and (max-width: 650px) {
      display: block;
      height: min-content;
      min-height: 0;
      flex-shrink: 0;
      overflow: auto;
  }
}
.mobile_gallery .gallery {
    height: 340px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    align-items: safe center;
    justify-content: safe center;
    padding-bottom: 30px;
    padding-top: 40px;
    max-width: unset;
}

pre {
  height: 800px;
  width: auto;
  overflow: scroll;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
  font-family: "synthese", "sans-serif", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 300;

}